import request from "@/config/serve.js";

// 查询
export function getDeveloperUserList(params) {
  return request("post", "/developerUser/getDeveloperUserList", params);
}

// 重置密码
export function resetPassword(params) {
  return request("post", "/developerUser/updatePasswordByIdAndEmail", params);
}

// 冻结-恢复账号
export function updateState(params) {
  return request("get", "/developerUser/updateStateById", params);
}

// 获取认证资料
// export function getVerifyInfo(params) {
//   return request("post", "/developerUser/getCertificationInformation", params);
// }
export function getVerifyInfo(params) {
  return request("post", "/developerUser/getDetailsById", params);
}
// 申请退款
export function requestRefund(params) {
  return request("post", "/developerAccount/requestRefund", params);
}
// 申请退款
export function getAccountBalance(params) {
  return request("post", "/developerAccount/getAccountBalance", params);
}
//商务联系人列表
export function getHonorManageUser(params) {
  // return request("post", "/honorManageUser/list", params);
  return request("post", "/honorManageUser/businessList", params);
}
// 发票抬头搜索
export function getInvoiceList(params) {
  return request("get", "/developerPaid/getInvoiceList", params);
}
